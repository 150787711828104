<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Company Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-tabs>
        <v-tab to="/company-details/initialization">Basic Initialization</v-tab>
      </v-tabs>
      <v-card-text>

       <!--   <v-tab
          dark
          to="/company-details"
        >General</v-tab>
        <v-tab to="/company-details/accounting">Accounting Data</v-tab> -->
        <v-row>
          <!-- <v-btn color="accent" small to="/new-customer">
              <v-icon left dark>mdi-plus</v-icon>New Customer
            </v-btn>-->
        </v-row>

        <div>
          <router-view />
        </div>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CompanyDetailsIndex",
  data: () => ({
    search: "",
  }),
  methods: {},
  created() {},
};
</script>